import Socials from "./Socials";

const AboutMe = () => {
  return ( 
    <div className="flex sm:flex-row flex-col gap-3 mb-5">
      <div className="flex flex-col">
        <img src="/images/jeanlouis.jpeg" className="w-[150px] rounded-[30px] mb-3 self-center" />
      </div>
      <div className="flex flex-col">
        <div className="font-semibold text-lg mb-3">
          About me
        </div>
        <div className="w-[350px] max-w-full mb-5">
          Software developer with over 5 years of experience in designing, developing, and maintaining software solutions. Highly skilled in debugging and troubleshooting, along with a meticulous attention to detail, I am dedicated to delivering high-quality code and exceeding client expectations.
        </div>
        <Socials />
      </div>
    </div>
  );
}
 
export default AboutMe;
