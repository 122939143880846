import { useState } from 'react';
import Project from './Project'

const Projects = () => {

  const [showAccordion, setShowAccordion] = useState(null);

  const handleAccordionClick = (clickedAccordion) => {
    if (showAccordion === clickedAccordion)
      setShowAccordion(null)
    else
      setShowAccordion(clickedAccordion)
  }

  return (
    <div className="flex flex-col text-white text-xl items-center">
      <h1 className="text-4xl mb-5">Projects</h1>
      <div className="flex flex-col">
        <Project
          id="climbersoap"
          title="Climbersoap"
          skills="PHP, MySQL"
          description="Climbersoap creates coconut oil soaps in the shape of climbing holds. This is a website I set-up, host, and maintain."
          url="https://climbersoap.com"
          url_label="climbersoap.com"
          logo="climbersoap.png"
          showAccordion={showAccordion}
          accordionHandler={handleAccordionClick}
        />
        <Project
          id="jloku"
          title="Jloku"
          skills="React, Python"
          description="Jloku is a 5x5 puzzle similar to Sudoku. A new puzzle is generated daily in the style of Wordle. This is a project I did for fun because I love puzzles."
          url="https://jloku.click"
          url_label="jloku.click"
          logo="jloku.png"
          showAccordion={showAccordion}
          accordionHandler={handleAccordionClick}
        />
        <Project
          id="hedy"
          title="Hedy"
          skills="Python, Typescript, Lark"
          description="Hedy is an opensource project. I implemented the ability to define and invoke functions into the trajectory."
          url="/documents/Enhancing_the_gradual_programming_language_Hedy_by_implementing_functions.pdf"
          url_label="Enhancing the gradual programming language Hedy by implementing functions"
          logo="hedy.png"
          showAccordion={showAccordion}
          accordionHandler={handleAccordionClick}
        />
        <Project
          id="grutto"
          title="Grutto"
          skills="React, Laravel, PHP"
          description="I worked for Grutto as the sole Developer for a while."
          url="https://grutto.com"
          url_label="Grutto"
          logo="grutto.jpeg"
          showAccordion={showAccordion}
          accordionHandler={handleAccordionClick}
        />
      </div>
    </div>
  );
}
 
export default Projects;
