import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import Social from './Social';

const Socials = () => {
  return ( 
    <div className="flex flex-wrap gap-3">
      <Social url="https://linkedin.com/in/jean-louis-le-a8815b151" icon={icon({ name: 'linkedin', style: 'brands' })} />
      <Social url="https://github.com/jlole/" icon={icon({ name: 'github', style: 'brands' })} />
    </div>
   );
}
 
export default Socials;
