import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

// import { useState } from "react";

const Project = ({ accordionHandler, showAccordion, id, title, skills, description, url, url_label, logo } ) => {
  const show = showAccordion === id;
  return (
    <div className="flex flex-col rounded-[15px] bg-white/25 w-[350px] max-w-full mb-5">
      <div onClick={() => accordionHandler(id)} className="flex items-center gap-3 cursor-pointer p-2 hover:text-teal-100">
        <div className={`flex grow alg w-[20px] right flex-row-reverse mx-3 ${show ? ' transition-all animate-pulse' : ''}`}>
          <span className={`${show ? 'rotate-90' : ''} transition-transform duration-300`}>
            <FontAwesomeIcon icon={icon({ name: "chevron-right", style: 'solid' })} />
          </span>
        </div>
        <div className="flex flex-col w-[340px] ">
          <div className={`font-bold text-lg `}>{title}</div>
          <div className={`italic text-sm `}>{skills}</div>
        </div>
        <img src={"/images/" + logo} className="w-[50px] h-[50px] rounded-[15px] bg-white" />
      </div>

      <div
        className = {`
          transition-[max-height padding]
          ease-linear
          translate
          duration-500
          overflow-hidden
          h-full
          px-3
          text-base
          ${show ? 'max-h-72' : 'max-h-0'}
          ${show ? 'p-1' : 'py-0'}
          ${show ? 'mb-2' : 'mb-0'}      
        `}
      >
        <div className="mb-5">
          {description}
        </div>
        {url && url_label && (
          <div className="text-teal-500">
            <span className="font-semibold">Check it here: </span>
            <a className="underline cursor-pointer" target="_blank" href={url}>{url_label}</a>
          </div>
        )}
      </div>
    </div>
  );
}
 
export default Project;
