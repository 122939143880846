import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Social = ({url, icon}) => {
  return ( 
    <div>
      <a target="_blank" href={url}>
        <FontAwesomeIcon size="2x" icon={icon} />
      </a>
    </div>
   );
}
 
export default Social;
