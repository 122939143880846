import Projects from './Projects'
import AboutMe from './AboutMe';

const Portfolio = () => {
  return (
    <div className="flex flex-col mt-20 lg:px-10 px-2 text-white items-center">
      <div>
        <h1 className="font-bold text-5xl mb-5">Jean Louis Le</h1>
        <h2 className="font-bold text-2xl mb-10 text-center">Software Developer</h2>
      </div>
      <div className="flex lg:flex-row flex-col max-w-full gap-3">
        <AboutMe />
        <Projects />
      </div>
    </div>
  );
}
 
export default Portfolio;
